.Election

{

/* box-sizing: border-box;


    width: 335px;
height: 95px;
left: 605.18px; */


font-family: 'Montserrat';
font-style: normal;
/* font-weight: ; */
/* font-size: 78.1073px; */
/* line-height: 95px;

color: #FFFFFF;
margin-left: 50%;
    padding-top: 15%; */
    
}
.DataRoom
{
  
    /* width: 656px;
    height: 136px;
    left: 605px;
    top: 494px; */
    
    font-family: 'Montserrat';
    font-style: normal;
    /* font-weight: 700;
    font-size: 111.582px;
    line-height: 136px; */
    /* identical to box height */
    
    
    /* color: #FFFFFF;
    margin-left: 50%;
    padding-top: 0%;
    margin-top: 5%; */



}

.head-bg{
    

    /* width: 100%;
    height: auto; */
   
    
    background: linear-gradient(272.59deg, #0C5789 18.84%, rgba(12, 87, 137, 0) 104.81%), 
    url('/public/images/Situation_Room_1.jpg');

    background-attachment: fixed;
    background-size:cover;
    z-index: -20;

    padding-bottom:10% ;
    animation-name:slider;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function:cubic-bezier(0.445, 0.05, 0.55, 0.95);

}

/* @keyframes slider{

    0%{


        background: linear-gradient(272.59deg, #0C5789 18.84%, rgba(12, 87, 137, 0) 104.81%), 
    url('/public/images/Situation_Room_1.jpg');
    margin-left: 100px;
    }

    25%{

        opacity: .3;
      

    }
    100%{
        opacity: 1;
        background: linear-gradient(272.59deg, #0C5789 18.84%, rgba(12, 87, 137, 0) 104.81%), 
        url('/public/images/Situation_Room_1.jpg');


    }



} */

.hero-tangline{

    /* color: #FFFFFF;
    margin-left:20%;
    margin-top: -5%; */



}
.explore{

    /* display: flex; */
    /* justify-content: space-between; */
    /* width: 202px;
height: 53px; */


background: #F33535;
border-radius: 100px;

color:white;

border-style:none;
filter: drop-shadow(0px 4px 12px rgba(220, 3, 36, 0.5));
/* margin-left: 50%; */

transition: background-color .2s ease-in .2s;
/* padding: -3%; */
/* box-sizing: border-box; */


}
.explore:hover
{


background-color:#B60923;
filter: drop-shadow(0px 4px 12px rgba(121, 16, 32, 0.5));




}

.explore h1 
{
    


/* background-color: aquamarine; */
/* margin-top: 10px; */

font-family: 'Montserrat';
font-style: normal;

/* identical to box height */


color: #FFFFFF;

}

.explore img{

/* margin-top: auto;
padding-left: 10%;
width: 18%;
padding-right: 3px;

margin-bottom: 4%; */
   
}

.e-droom{




font-weight:normal;


}
.tangline{

font-weight: lighter;



}