

.navbarItems{

 /* height: 80px; 
background-color:white;
 display: flex; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), 0px 4px 12px 22px rgba(12, 86, 137, 0.1);

justify-content: center;
align-items: center;
font-size: 1.2rem; */


}

.navbar-logo{

    display: flex;
    justify-self: start;
    /* margin-left: 20px; */
    cursor:pointer;
    background-image: url('/public/kdireallogo.png');
    background-size:contain;
    background-repeat: no-repeat;
   
    width: 120px;
height: 60px;
align-items: center;
/* margin-top: 45px; */

border-color: aliceblue;
transition: background-color .2s ease-in .2s;
transition: background-image .2s ease-in .2s;
 /* background-color: blue;  */
margin-right: 200px;

margin-top: 10px;


}
.navbar-logo:hover{

    
    /* background-image: url('/public/kdi_white.png'); */
    /* background-color: #18347A ; */
    box-shadow: 1px 12px 9px 1px rgba(241, 163, 159, 0.1);

}



div ul  ,.Collapse {


display: grid;

list-style: none;


/* display: flex; */
list-style: none;
/* flex-direction: row; */



grid-template-columns: repeat(3,auto);
grid-gap: 30px;
justify-content: end;

font-family: 'Montserrat';
font-style: normal;
/* font-weight: 700;
font-size: 17.1479px;
line-height: 21px;
padding-bottom: -20px;
padding-top: 10px; */





}
nav ul li a  {


    /* display: grid;
    
   text-decoration: none;
    color: #18347A;
    
margin-bottom:18px;
padding-top: 10px; */
    
    }

    nav ul li a:hover {

        
        color: white;

        transition: ;

       
        
        }
        
        .li-Element:hover {

        /* background-color: #FBE6E6; */
        
        border-radius: 10px;
        
        padding: auto;

        box-shadow: 0px 4px 8px 2px rgba(255, 32, 32, 0.1);

       

       
        
        }

        .li-Element {

            
            /* padding: 10px; */
    
            transition: background-color .2s ease-in .2s;
    
           
            
            }


.nav-hover{

/* position: absolute; */
width: 99px;
height: 3px;
left: 869px;
top: 203px;

background: #1F78B4;




}



/* Search Bar */


:root {
    --rad: .7rem;
    --dur: .3s;
    --color-dark: #2f2f2f;
    --color-light: #fff;
    --color-brand: #57bd84;
    --font-fam: 'Lato', sans-serif;
    --height: 5rem;
    --btn-width: 6rem;
    --bez: cubic-bezier(0, 0, 0.43, 1.49);
  }
  
  /* // Setup */
 
  .input-search:focus{



    

  }
  .Collapse{

display: flex;
justify-content: center;

   
  }
 