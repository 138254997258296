
.h-100{
    height: 100vh!important;
}

.sec2-container{



    background-color: aliceblue;

    background-image: url('/public/images/grid\ lines.png');
    background-repeat:repeat;
width: auto;
min-height: 700px;


  
}

.finger_background


{

   
    /* width: auto;
    height: 88.89px;

    background: url('/public/images/Fingerprint-Free-Download-PNG.png');
    background-repeat: no-repeat;
    opacity: 0.7;
    transform: matrix(-0.95, -0.3, -0.34, 0.94, 0, 0);
    margin: auto;
    display: flex;
    justify-content: center;
     */


}
.button{
    
/* width: 322px;
height: 323px;
left: 360px;
top: 1188px; */

background: url('/public/images/picture.png'), #FFEBEE;
border-radius: 59px;
/* rotate: 19deg; */




}


.road-state:hover{



    background-color: #BE1E2D;;

    box-shadow: 1px 12px 9px 1px rgba(241, 163, 159, 0.1);
    color: white;



    
}

.road-state{

    transition: background-color .2s ease-in .2s;

    outline:none;


}
.road-presidency:hover{



    background-color: #BE1E2D;;

    box-shadow: 1px 12px 9px 1px rgba(241, 163, 159, 0.1);
    color: white;



    
}

.road-presidency{

    transition: background-color .2s ease-in .2s;

    outline:none;


}




.step {


   



}


.button-hr{


    margin: auto;
    width: 2%;
    border-width: 4px;
    
   
   
    




}

.button-hr:hover{

    animation-duration: 3s;
animation-name: move;


animation-timing-function:cubic-bezier(0.445, 0.05, 0.55, 0.95);
animation-iteration-count:infinite;    


    

}

@keyframes move {


from{

    width: 2%;
    color:red;
}

to{


width: 100%;
color:white;

}
    
}



.road-to-state-text:hover{




    color:white;
}

.bg-fingerprint{




    background-image: url(/public/images/bg-fingerprint.png);
    background-size: contain;
    background-repeat: no;
    height: 150%;
    
    



}


.step1{

   
    width: 678px;
    height: 77px;
    left: 644px;
    top: 683px;
    
    background: #0C5789;


}

.step2
{
  
    width: 844px;
    height: 86px;
    left: 561px;
    top: 828px;
    
    background: #0C5789;



}

.step3{


    
width: 986px;
height: 119px;
left: 498px;
top: 962px;

background: #0C5789;
}

.EVNumber{




    color:  #0C5789;;
}

.finger {

background: url('/public/images/Fingerprint-Free-Download-PNG.png');
background-size: contain;
background-repeat: no-repeat;
opacity: .9;
box-shadow: 0px 4px 26px 5px rgba(0, 0, 0, 0.25);
/* padding: 2%; */

}
.blue-box{

    background-color:#0C5789;
  

    /* max-height:50%;
    height:50%;
    padding-top:4px;
    height: fit-content;  */
    
    margin: 2px;
    /* max-height:40%; */
    box-sizing: border-box;


}
.yellow-box{

     background-color:#F6C310;;
    
     margin: 2px;
     /* max-height: 40%; */

    /* max-height:50%;
    height:50%;
    padding-top:4px;
    height: fit-content;  */


}
.finger div{

   


}

.blue-box div img{


/* width:30%;
background-color: #BE1E2D; */


}
.blue-box  h2{


     
     
    
    }
    .blue-box div h4 {



       
    }




    .yellow-box  h2{


      
        
       
       }
       .yellow-box div h4 {
   
   
   
           
           
       }

  /* Gender of victims */

       .circle-1{


        
        border-radius: 50%;
        width: 250px;
        height: 250px;
        background-color: #0C5789;

        position:absolute;
        
       }
       .circle-2{


        border-radius: 50%;
        width: 250px;
        height: 250px;
        background-color: #0C5789;

        position:absolute;
        left: 140px;
        
       }
       #circleHolder{

        position: relative;
        
        padding-top: 6%;
        
        max-width:120%;


       }


       .rotate{


        rotate:270deg;

        


       }
       .overlap-percent
       {

        z-index: 1;
        



       }
       

       .line  
       {




        
       
        height: 100px;
       margin: auto 60px;
        
        border: 1.5px solid #FFFFFF;
        
        z-index: 1;        

       }
.Gender-Gender{


    height:min-content;
    margin:auto -60px;


}
.Gender-img-box{

    height:min-content;
    margin:auto 30px;


}

.text-figure {


    height:min-content;
    margin:auto -50px;


}

.offcircle-election-header {
    font-family: poppins;
    font-size: 18pt;
    font-weight: 800;
    padding: 30px 20px;
    background-color: #212529;
    color: white;
}

.viz-container {
    background-color: #212529!important;
}

       /* media queries */

       @media (max-width: 576px) {
        /* Your CSS rules for screens of all sizes go here */


        .circle-1{


        
            border-radius: 50%;
            width: 180px;
            height: 180px;
            background-color: #0C5789;
    
            position:absolute;
            margin-left: 60px;
            
           }
           .circle-2{
    
    
            border-radius: 50%;
            width: 180px;
            height: 180px;
            background-color: #0C5789;
    
            position:absolute;
            left: 71px;
            top: 200px;
            margin-top: 10px;
            

           }

           .line  
       {




        
        display: none;      

       }

       .Gender-Gender{


        height:min-content;
        margin:auto 9px;
    
    
    }
    .Gender-img-box{
    
        height:min-content;
        margin:auto -40px;
       
    
    
    }
    
    .text-figure {
    
    
        height:min-content;
        margin:auto 30px;
    
    
    }
      }
    

      .unidentified-gender{

        position: absolute;
        left: 420px;
        top:100px;



      }

      /* iframe */

      .iframe{

        width:1100px ;
        height:730px ;
        margin: 0px;



      }

      @media (max-width: 576px) {

        .iframe{

            width:1100px ;
            height:200px ;
            margin: 20px auto;
    
    
    
    
          }




      }
      @media (min-width: 768px) {

        .iframe{

            width:1100px ;
            height:400px ;
            margin: 20px auto;
    
    
    
    
          }




      }
      @media (min-width: 1280px) {

        .iframe{

            width:1100px ;
            height:630px ;
            /* margin: 20px auto; */
            margin: 0px;
    
    
    
          }
          .NavELection
          {

            /* background-color: #BE1E2D; */
            margin-Left:140px;


          }





      }
      @media (width: 1024px) {

        .iframe{

            width:1100px ;
            height:630px ;
            /* margin: 20px auto; */
    
    
    
    
          }
       



      }


    
      