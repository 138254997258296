.btn-finger{


background: url('/public/images/Fingerprint-Free-Download-PNG.png');
background-size: cover;


}
.btn-finger:hover{

    box-shadow: 0px 4px 3px #8D0505, 0px 15px 59px rgba(0, 0, 0, 0.24);


}

#btn-finger:hover{


    box-shadow: 0px 4px 3px #8D0505, 0px 15px 59px rgba(0, 0, 0, 0.24);




}

.btn-viewD:hover ,#back:hover {


    box-shadow: 0px 4px 3px #8D0505, 0px 15px 59px rgba(0, 0, 0, 0.24);




}